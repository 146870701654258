<template>
  <div class="main">
    <!-- <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
    </el-breadcrumb> -->
    <el-card
      shadow="hover"
      class="card-sty"
      body-style="height:100%;display: flex;flex-direction: column;justify-content: space-between;"
    >
      <div>
        <el-button
          type="primary"
          size="small"
          @click="addUser"
          v-auth="'userManagement-add'"
          >新增用户</el-button
        >&nbsp;
        <el-button size="small" @click="showDrawer">按条件搜索</el-button>
      </div>
      <el-table
        style="margin-top: 0; margin-bottom: 0; flex: 1;margin-top: 14px;"
        height="100%"
        v-loading="spinning"
        :data="data"
        row-key="id"
        header-row-class-name="my-table-head-style"
      >
        <el-table-column type="index"> </el-table-column>
        <el-table-column prop="imgPathAll" label="头像">
          <template slot-scope="scope">
            <a-avatar
              v-if="
                scope.row.imgPathAll === null || scope.row.imgPathAll === ''
              "
              icon="user"
            />
            <a-avatar v-else :src="scope.row.imgPathAll" />
          </template>
        </el-table-column>
        <el-table-column prop="username" label="用户名"> </el-table-column>
        <el-table-column prop="phone" label="联系方式"> </el-table-column>
        <el-table-column prop="roles" label="角色"> </el-table-column>
        <el-table-column prop="organizationName" label="所属机构">
        </el-table-column>
        <el-table-column prop="sex" label="性别"> </el-table-column>
        <el-table-column prop="state" label="状态">
          <template slot-scope="scope">
            <a-badge
              :status="
                scope.row.state === 'USABLE'
                  ? 'success'
                  : scope.row.state === 'LOCK'
                  ? 'warning'
                  : 'error'
              "
            />
            {{ scope.row.state | StateFiltr }}
          </template>
        </el-table-column>
        <el-table-column
          width="200"
          label="操作"
          align="center"
          v-if="authVerify.verify('userManagement-edit,userManagement-delete')"
        >
          <!--<template slot="header" slot-scope="scope">
                    <el-button  size="mini"
                               @click="editUser(scope.row)">搜索
                    </el-button>
                    <el-button  size="mini"
                               @click="editUser(scope.row)">添加用户
                    </el-button>
                </template>-->
          <template slot-scope="scope">
            <el-button
              icon="el-icon-edit-outline"
              size="mini"
              v-auth="'userManagement-edit'"
              @click="editUser(scope.row)"
              >编辑
            </el-button>
            <el-button
              icon="el-icon-delete"
              size="mini"
              v-auth="'userManagement-delete'"
              @click="deleteUser(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          layout="prev, pager, next"
          :current-page="currentPage"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          :total="totalCount"
        >
        </el-pagination>
      </div>
    </el-card>
    <!-- 添加 -->
        <a-modal v-model="addUserVisible" title="新增用户" class="model-sty" width="550px">
          <template slot="footer">
            <a-button @click="addUserVisible = false">取消</a-button>
            <a-button type="primary" @click="toAddUser"> 确认 </a-button>
          </template>
          <a-form-model
            :model="userFormValue"
            ref="userRefFormAdd"
            :rules="userRules"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 14 }"
          >
            <a-form-model-item label="用户名" prop="username">
              <a-input v-model="userFormValue.username" placeholder="请输入用户名"/>
            </a-form-model-item>
            <a-form-model-item label="密码" prop="password">
              <a-input v-model="userFormValue.password" placeholder="请输入密码"/>
            </a-form-model-item>
            <a-form-model-item label="角色" prop="roleIds">
              <template>
                <!--:default-value="['a1', 'b2']"-->
                <a-select
                  mode="multiple"
                  style="width: 100%"
                  placeholder="请选择角色"
                  v-model="userFormValue.roleIds"
                >
                  <a-select-option v-for="role in roleListData" :key="role.id">
                    {{ role.roleName }}
                  </a-select-option>
                </a-select>
              </template>
            </a-form-model-item>
            <a-form-model-item label="所属机构" prop="organizationId">
              <template>
                <a-tree-select
                  :replaceFields="replaceFields"
                  v-model="userFormValue.organizationId"
                  style="width: 100%"
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                  :tree-data="organizationOneselfList"
                  placeholder="请选择所属机构"
                  tree-default-expand-all
                >
                </a-tree-select>
              </template>
            </a-form-model-item>
            <a-form-model-item label="联系方式" prop="phone">
              <a-input v-model="userFormValue.phone" placeholder="请输入联系方式"/>
            </a-form-model-item>
            <a-form-model-item label="性别" prop="sex">
              <a-select v-model="userFormValue.sex" placeholder="请选择性别">
                <a-select-option key="男"> 男 </a-select-option>
                <a-select-option key="女"> 女 </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="头像" prop="img">
              <a-upload-dragger
                name="file"
                :multiple="false"
                :action="httpUploadSrc"
                :showUploadList="false"
                :beforeUpload="handleBeforeUpload"
                @change="menuLogoHandleChange"
              >
                <div v-if="!isShowImg">
                  <p class="ant-upload-drag-icon">
                    <a-icon type="inbox" />
                  </p>
                  <p class="ant-upload-text">点击上传图片</p>
                </div>
                <div v-else>
                  <img
                    v-if="userFormValue.imgPathAll"
                    :src="userFormValue.imgPathAll"
                    width="100%"
                    alt=""
                  />
                </div>
              </a-upload-dragger>
            </a-form-model-item>
          </a-form-model>
        </a-modal>
    <!-- 编辑 -->
        <a-modal v-model="editUserVisible" title="编辑用户" class="model-sty" width="600px">
          <template slot="footer">
            <a-button @click="editUserVisible = false">取消</a-button>
            <a-button type="primary" @click="toEditUser"> 确认 </a-button>
          </template>
          <a-form-model
            :model="userFormValue"
            ref="userRefFormEdit"
            :rules="userRules"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 14 }"
          >
            <a-form-model-item label="用户名" prop="username">
              <a-input v-model="userFormValue.username" placeholder="请输入用户名"/>
            </a-form-model-item>
            <a-form-model-item label="密码" prop="password">
              <a-input v-model="userFormValue.password" placeholder="请输入密码"/>
            </a-form-model-item>
            <a-form-model-item label="角色" prop="roleIds">
              <template>
                <!--:default-value="['a1', 'b2']"-->
                <a-select
                  mode="multiple"
                  style="width: 100%"
                  placeholder="请选择角色"
                  v-model="userFormValue.roleIds"
                >
                  <a-select-option v-for="role in roleListData" :key="role.id">
                    {{ role.roleName }}
                  </a-select-option>
                </a-select>
              </template>
            </a-form-model-item>
            <a-form-model-item label="所属机构" prop="organizationId">
              <template>
                <a-tree-select
                  :replaceFields="replaceFields"
                  v-model="userFormValue.organizationId"
                  style="width: 100%"
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                  :tree-data="organizationOneselfList"
                  placeholder="请选择所属机构"
                  tree-default-expand-all
                >
                </a-tree-select>
              </template>
            </a-form-model-item>
            <a-form-model-item label="联系方式" prop="phone">
              <a-input v-model="userFormValue.phone" placeholder="请输入联系方式"/>
            </a-form-model-item>
            <a-form-model-item label="性别" prop="sex">
              <a-select v-model="userFormValue.sex" placeholder="请选择性别">
                <a-select-option key="男"> 男 </a-select-option>
                <a-select-option key="女"> 女 </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="头像" prop="img">
              <a-upload-dragger
                name="file"
                :multiple="false"
                :action="httpUploadSrc"
                :showUploadList="false"
                :beforeUpload="handleBeforeUpload"
                @change="menuLogoHandleChange"
              >
                <div v-if="!isShowImg">
                  <p class="ant-upload-drag-icon">
                    <a-icon type="inbox" />
                  </p>
                  <p class="ant-upload-text">点击上传图片</p>
                </div>
                <div v-else>
                  <img
                    v-if="userFormValue.imgPathAll"
                    :src="userFormValue.imgPathAll"
                    width="100%"
                    alt=""
                  />
                </div>
              </a-upload-dragger>
            </a-form-model-item>
          </a-form-model>
        </a-modal>

    <!--搜索-->
    <template>
      <div>
        <a-drawer
          title="搜索用户"
          width="520"
          placement="right"
          :closable="false"
          :visible="drawerVisible"
          @close="onDrawerClose"
        >
          <div>
            <a-form-model
              :model="userQuery"
              :label-col="{ span: 7 }"
              :wrapper-col="{ span: 16 }"
            >
              <a-row>
                <a-col :span="12">
                  <a-form-model-item label="用户名" prop="username">
                    <a-input v-model="userQuery.username" allowClear placeholder="请输入用户名" />
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item label="状态" prop="state">
                    <a-select v-model="userQuery.state" allowClear placeholder="请选择状态">
                      <a-select-option key="USABLE"> 正常 </a-select-option>
                      <a-select-option key="LOCK"> 锁定 </a-select-option>
                      <a-select-option key="DISABLED"> 禁用 </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
              </a-row>
            </a-form-model>
            <div
              :style="{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
                zIndex: 1,
              }"
            >
              <a-button :style="{ marginRight: '8px' }" @click="removeAllQuery">
                清空
              </a-button>
              <a-button type="primary" @click="searchQuery"> 搜索 </a-button>
            </div>
          </div>
        </a-drawer>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      httpUploadSrc: this._axios_env.SERVER+`/file/img?access_token=${
        JSON.parse(localStorage.getItem("user_msg")).token
      }`,
      currentPage: 1,
      pageSize: 10,
      totalCount: 0,
      drawerVisible: false,
      userFormValue: {
        imgPathAll: "",
        img: "",
        id: "",
        username: "",
        password: "",
        phone: "",
        sex: undefined,
        state: "",
        organizationId: undefined,
        roleIds: [],
      },
      userRules: {
        username: [
          { required: true, message: "请输入用户名!", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码!", trigger: "blur" }],
          phone: [{ required: true, message: "请输入联系方式!", trigger: "blur" }],
        organizationId: [
          { required: true, message: "请选择所属机构!", trigger: "change" },
        ],
        roleIds: [
          { required: true, message: "请选择角色!", trigger: "change" },
        ],
        sex: [{ required: true, message: "请选择性别!", trigger: "change" }],
      },
      userQuery: {
        username: "",
        state: undefined,
      },
      spinning: true,
      screenWidth: document.documentElement.clientWidth, //屏幕宽度
      screenHeight: document.documentElement.clientHeight - 50, //屏幕高度
      addUserVisible: false,
      editUserVisible: false,
      data: [],
      roleListData: [],
      organizationOneselfList: [],
      topOrganizationList: [],
      replaceFields: {
        children: "children",
        value: "id",
        key: "id",
        title: "name",
      },
    };
  },
  watch: {
    screenWidth: function (val) {
      //监听屏幕宽度变化
      console.log(val);
    },
    screenHeight: function (val) {
      //监听屏幕高度变化
      console.log(val);
    },
  },
  mounted() {
    const _this = this;
    window.onresize = function () {
      // 定义窗口大小变更通知事件
      _this.screenWidth = document.documentElement.clientWidth; //窗口宽度
      _this.screenHeight = document.documentElement.clientHeight - 50; //窗口高度
    };
  },
  created() {
    this.getUserData();
    this.getRoleList();
    this.getOrganizationOneselfList();
    this.getOnlyTopOrganizationList();
  },
  filters: {
    StateFiltr: function (value) {
      if (!value) return "";
      if (value === "USABLE") {
        return "正常";
      } else if (value === "LOCK") {
        return "锁定";
      } else if (value === "DISABLED") {
        return "禁用";
      } else {
        return "禁用";
      }
    },
    StateTypeFiltr: function (value) {
      if (!value) return "";
      if (value === "USABLE") {
        return "success";
      } else if (value === "LOCK") {
        return "锁定";
      } else if (value === "DISABLED") {
        return "warning";
      } else {
        return "danger";
      }
    },
  },
  computed: {
    pageHeight: function () {
      let height = document.documentElement.clientHeight;
      return height - 50;
    },
    isShowImg() {
      if (this.userFormValue.img === "" || this.userFormValue.img === null) {
        return false;
      } else {
        var reg = /null/;
        return !reg.test(this.userFormValue.img);
      }
    },
  },
  methods: {
    //上传文件之前的钩子
    handleBeforeUpload(file, fileList) {
      const reg = /\.(png|jpg|jpeg|gif)$/gi;
      const filename = file.name;
      if (reg.test(filename) === false) {
        this.$message.error("请上传正确的图片格式！");
        return false;
      }
    },
    //上传文件改变时的状态
    menuLogoHandleChange({ file, fileList, event }) {
      if (file["response"] !== undefined) {
        this.userFormValue.imgPathAll = file["response"].data.allPath;
        this.userFormValue.img = file["response"].data.path;
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getUserData();
    },
    showDrawer() {
      this.drawerVisible = true;
    },
    onDrawerClose() {
      this.drawerVisible = false;
    },
    getOrganizationOneselfList() {
      this.http.organization.getOrganizationOneselfList({}).then((res) => {
        if (res.data.status == 200) {
          this.organizationOneselfList = res.data.data;
        } else {
          this.$message.warning("数据获取失败");
        }
      });
    },
    getOnlyTopOrganizationList() {
      this.http.organization.getOnlyTopOrganizationList().then((res) => {
        if (res.data.status == 200) {
          this.topOrganizationList = res.data.data;
        } else {
          this.$message.warning("数据获取失败");
        }
      });
    },
    getRoleList() {
      this.http.user.getRoleList().then((response) => {
        if (response.data.status == 200) {
          this.roleListData = response.data.data;
        } else {
          this.$message.warning("数据获取失败");
        }
      });
    },
    toAddUser() {
      /*this.$message.warning(this.userFormValue.organizationId);*/
      this.$refs.userRefFormAdd.validate((valid) => {
        if (valid) {
          this.http.user.createUser(this.userFormValue).then((response) => {
            if (response.data.status == 200) {
              this.$message.success(response.data.msg);
              this.addUserVisible = false;
            } else {
              this.$message.warning(response.data.msg);
            }
            this.getUserData();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    deleteUser(key) {
      let self = this;
      this.$confirm({
        title: "确定删除吗?",
        content: "",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          let params = {
            id: key.id,
          };
          this.http.user.deleteUser(params).then((response) => {
            if (response.data.status == 200) {
              this.$message.success(response.data.msg);
              self.getUserData();
            } else {
              this.$message.error(response.data.msg);
            }
          });
        },
        onCancel() {},
      });
    },
    editUser(record) {
      console.log(record);
      this.userRules.password[0].required = false;
      this.cleanUser();
      this.userFormValue = {
        imgPathAll: record.imgPathAll,
        img: record.img,
        id: record.id,
        username: record.username,
        phone: record.phone,
        sex: record.sex,
        state: record.state,
        organizationId: record.organizationId,
        roleIds: record.roleIds,
      };
      this.editUserVisible = true;
        this.$refs.userRefFormEdit.resetFields()
    },
    addUser() {
      this.userRules.password[0].required = true;
      this.cleanUser();
      this.addUserVisible = true;
        this.$refs.userRefFormAdd.resetFields();
    },
    cleanUser() {
      this.userFormValue = {
        imgPathAll: "",
        img: "",
        id: "",
        username: "",
        password: "",
        phone: "",
        sex: undefined,
        state: "",
        organizationId: undefined,
        roleIds: [],
      };
    },
    getUserData() {
      // pagesize current 搜索的数据  旁边树的id
      let parmas = {
        username: this.userQuery.username,
        state: this.userQuery.state,
        size: this.pageSize,
        current: this.currentPage,
      };
      this.http.user.getUserList(parmas).then((response) => {
        if (response.data.status == 200) {
          this.spinning = false;
          this.data = response.data.data.records;
          this.totalCount = response.data.data.total;
          this.currentPage = response.data.data.current;
        } else {
          this.$message.warning("数据获取失败");
        }
      });
    },
    toEditUser() {
      this.$refs.userRefFormEdit.validate((valid) => {
        if (valid) {
          this.http.user.updateUser(this.userFormValue).then((response) => {
            if (response.data.status == 200) {
              this.$message.success(response.data.msg);
              this.editUserVisible = false;
            } else {
              this.$message.warning(response.data.msg);
            }
            this.getUserData();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    removeAllQuery() {
      this.userQuery = {
        username: "",
        state: undefined,
      };
      this.searchQuery();
    },
    searchQuery() {
      this.spinning = true;
      this.getUserData();
      this.drawerVisible = false;
    },
  },
};
</script>

<style scoped>
/*滚动条整体样式*/
.scroll_bar::-webkit-scrollbar {
  width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
}

.scroll_bar::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background-color: #cecece;
}

.scroll_bar::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #ededed;
}
</style>
<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.main {
  padding: 14px;
  height: 100%;
  .card-sty {
    height: 100%;
  }
}
// .model-sty{
//   height: 80%;
//   overflow-y: auto;
// }
// .model-sty::-webkit-scrollbar {
//   display: none; /* Chrome Safari */
// }
// .model-sty {
//   scrollbar-width: none;
// }
.model-sty /deep/ .ant-modal {
  height: 80% !important;
}
.model-sty /deep/ .ant-modal-content {
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
}
.model-sty /deep/ .ant-modal-body {
  height: 100%;
  overflow-y: auto;
}
.model-sty /deep/ .ant-modal-body::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 3px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.model-sty /deep/ .ant-modal-body::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background-color: #1890ff;
}
.model-sty /deep/ .ant-modal-body::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #ededed;
}
</style>
